<template>
  <div class="log">
    <button @click="getResponse" class="view-ass-btn" over>View Bids</button>

    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat style="background-color: #fee9d0">
            <v-spacer></v-spacer>
            <v-toolbar-title>
              {{ this.bid.jobModel }} Model Response
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-layout pt-3>
            <v-flex xs6 md6 class="timer-style"
              >Job Id - {{ bid.jobId }} (Total trucks :
              {{ bid.assignCount || bid.requiredTrucks }})
            </v-flex>

            <v-flex xs4 md6>
              <vue-countdown-timer
                class="timer-style"
                @start_callback="startCallBack('event started')"
                @end_callback="endCallBack('event ended')"
                :start-time="this.currentDate"
                :end-time="this.bid.closedDate"
                :interval="1000"
                :start-label="'Time left:'"
                :end-label="'Time left'"
                label-position="begin"
                :end-text="'Bid is closed'"
                :day-txt="'Days/Day '"
                :hour-txt="'    : '"
                :minutes-txt="': '"
                :seconds-txt="'(HH:MM:SS)'"
              >
                <template slot="countdown" slot-scope="scope">
                  <span>{{ scope.props.days }}</span>
                  <i v-if="scope.props.days > 1">days</i>
                  <i v-else>day</i>
                  <span>{{ scope.props.hours }}</span>
                  <i>{{ scope.props.hourTxt }}</i>
                  <span>{{ scope.props.minutes }}</span>
                  <i>{{ scope.props.minutesTxt }}</i>
                  <span>{{ scope.props.seconds }}</span>
                  <i>{{ scope.props.secondsTxt }}</i>
                </template>
              </vue-countdown-timer>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs6 md6 class="timer-style pt-7" v-if="bid.commentForBid">
              <p>Comments - {{ bid.commentForBid }}</p>
            </v-flex>
          </v-layout>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class>
            <v-card-text class="nospace">
              <v-data-table
                id="jobsTable"
                hide-actions
                :headers="headers"
                :items="items.list"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.item.transporter[0].transporterName }} ({{
                      props.item.transporter[0].transporterId
                    }})
                    <br />

                    {{ props.item.transporter[0].email }}
                    <br />
                    {{ props.item.transporter[0].countryCode }}-{{
                      props.item.transporter[0].phoneNo
                    }}
                  </td>
                  <td>{{ getTime(props.item.updatedAt) }}</td>

                  <td>{{ props.item.amount }} {{ transporterCurrency }}</td>
                  <td>{{ props.item.providedTrucks }}</td>
                  <td>
                    {{ props.item.amount / props.item.providedTrucks }}
                    {{ transporterCurrency }}
                  </td>

                  <td>
                    <v-tooltip bottom v-if="props.item.comments">
                      <template v-slot:activator="{ on }">
                        <span class="fix-width-td" v-on="on">{{
                          props.item.comments
                        }}</span>
                      </template>
                      <span>{{ props.item.comments }}</span>
                    </v-tooltip>
                    <span v-if="!props.item.comments">-</span>
                  </td>
                  <td class>
                    <span
                      class="countss"
                      v-if="showAccept && !props.item.isApproved"
                      >Pending</span
                    >

                    <span
                      class="counts"
                      v-if="!showAccept && !props.item.isApproved"
                      >Rejected</span
                    >

                    <span
                      class="count"
                      v-if="
                        props.item.isApproved || props.item.jobModel == 'Fixed'
                      "
                      >Accepted</span
                    >
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>
<script>
import Bidpopup from "@/components/Procurement/Bidpopup";
import ApprovalPopup from "@/components/Pop-ups/ApprovalPopup.vue";
import ErrorBox from "@/components/Common/ErrorBox";
import { sendBidpopupTransporterResponseList } from "@/constants/datatable-headers.js";
import moment from "moment";
import { tenderSectionApiUrl } from "../../constants/api-urls";
import { StorageKeys } from "../../constants/constants";

export default {
  name: "Timer",
  components: {
    Bidpopup,
    ErrorBox,
    ApprovalPopup,
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  data() {
    return {
      openConfirmationDailog: false,
      currentDate: new Date().toLocaleString(),
      error: "",
      transporterCurrency: this.bid.transporterCurrency,

      totalTrucks: this.bid.assignCount,
      amount: this.bid.amount,
      JModel: this.bid.jobModel,
      showAccept: true,

      loading: false,
      x: {
        error: null,
      },
      is_accepted: false,
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      headers: sendBidpopupTransporterResponseList,
    };
  },
  props: {
    bid: Object,
  },
  created() {
    this.$emit("response-job", this.bid);
  },

  methods: {
    getTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    open() {
      this.openConfirmationDailog = true;
    },
    confirmationToAccept() {
      this.openConfirmationDailog = false;
    },
    startCallBack: function (x) {
      this.loading = false;
    },
    endCallBack: function (x) {
      this.loading = false;
    },
    getResponse() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.dialog = true;

      (this.loading = "true"), this.$emit("response-job", this.bid);

      this.dialog = true;
      this.processing = true;
      let url = tenderSectionApiUrl.viewBid;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.bid._id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = true;
          this.items = response.data.data;

          this.$emit("response-job", this.bid);

          this.processing = false;
          this.loading = false;
          let showAccept = true;
          let data = response.data.data.list.filter((ele) => {
            return ele.isApproved === true;
          });

          if (data.length) {
            this.showAccept = false;
          }
        },
        (error) => {
          this.error = "Failed to Fetch Assignments";
          this.processing = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.v-dialog {
  overflow-y: auto !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;

  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.max {
  max-width: 70%;
}
.fix-width-td {
  display: block;
  max-width: 200px !important;
  word-break: break-word;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.count {
  background-color: green;
  color: white !important;
  font-size: 14px !important;

  border-radius: 5px;
}
.counts {
  background-color: red;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;

  border-radius: 5px;
}
.countss {
  background-color: grey;
  color: white !important;
  font-size: 14px !important;
  padding: 0 5px;

  border-radius: 5px;
}
.flexx {
  display: flex;
}
.pd5 {
  padding-top: 18px;
  padding-bottom: 5px;
}
.pt-7 {
  padding-left: 7px !important;
}
.timer-style {
  word-break: break-word;
  padding-left: 10px;
  font-size: large;
}
</style>
