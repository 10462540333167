<template onlaod="searchHistory">
  <div>
    <v-card class>
      <v-card-text class="nospace">
        <v-data-table
          id="jobsTable"
          hide-actions
          :headers="headers"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <tr
              :key="props.index"
              :class="[
                getDate(props.item.startDate, props.item.pickupTime) <= 12 &&
                getDate(props.item.startDate, props.item.pickupTime) > 6 &&
                (props.item.jobStatus === 3 || props.item.jobStatus === 4)
                  ? 'colorchangeyellow'
                  : '',
                getDate(props.item.startDate, props.item.pickupTime) <= 6 &&
                (props.item.jobStatus === 3 || props.item.jobStatus === 4)
                  ? 'colorchange'
                  : '',
              ]"
            >
              <td>
                {{ props.item.jobId }}
                <!-- <ActivityLogs
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item._id"
                  :id="props.item.jobId.toString()"
                /> -->
                <activityLogs
                  :permissions="'activity-logs-jobs'"
                  :type="'jobs'"
                  :_id="props.item._id"
                  :id="props.item.jobId.toString()"
                />
              </td>
              <td v-if="props.item.customer">
                {{ props.item.customer }}
                <br />
                {{ props.item.customerEmail }}
              </td>
              <td v-else>{{ props.item.customerEmail }}</td>

              <td>{{ props.item.numberOfAssignmentsRequired }}</td>

              <td>{{ getTime3(props.item.pickupDate) }}</td>
              <td style="width: 10%">
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator"
                    >{{ props.item.sourceCity }},
                    {{ props.item.sourceCountry }}</template
                  >
                  <span class="tooltip">{{ props.item.sourceLocation }}</span>
                </v-tooltip>
              </td>
              <td style="width: 10%">
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator"
                    >{{ props.item.destinationCity }},
                    {{ props.item.destinationCountry }}</template
                  >
                  <span class="tooltip">{{
                    props.item.destinationLocation
                  }}</span>
                </v-tooltip>
              </td>

              <!-- <td>{{ getStatus(props.item.jobStatus) }}</td> -->
              <td v-if="props.item.jobStatus == 6">
                {{ props.item.transit }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 7">
                {{ props.item.shipment }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 8">
                {{ props.item.paymentPending }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 9">
                {{ props.item.paymentDone }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td
                v-else-if="
                  props.item.jobStatus == 5 ||
                  props.item.jobStatus == 4 ||
                  props.item.jobStatus == 1
                "
              >
                {{ props.item.Assigned }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else>
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>

              <td style="width: 10%">
                {{ jobsStatus(props.item.jobStatus) }}
              </td>

              <td class>
                <!--  <button
                  @click="goToProfile(props.item.jobId)"
                  target="_blank"
                  class="track-ass-btn"
                >Details</button>-->

                <v-btn
                  flat
                  @click="goToProfile(props.item.jobId)"
                  small
                  color="primary"
                  >VIEW DETAIL<v-icon>arrow_right</v-icon></v-btn
                >
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :url="paginationURL"
          :callback="callback"
          :type="0"
          :action="`bookings`"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
      <v-snackbar
        :timeout="6000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card>
  </div>
</template>

<script>
import Bidpopup from "@/components/Procurement/Bidpopup";
import { EventBus } from "../../event-bus.js";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { customerJobList } from "@/constants/datatable-headers.js";
import _ from "lodash";
import moment from "moment";
let momentTz = require("moment-timezone");
import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
import DriverProfileForm from "@/components/Drivers/DriverProfileForm";
import PaginationButtons from "@/components/Pagination/PaginationButtons";
import pagination from "@/components/Pagination/pagination";
import activityLogs from "../Common/activityLogs";
import { custDetails, sendJobToTransport } from "../../constants/api-urls";
import Responsepopup from "@/components/Procurement/Responsepopup";

import {
  StorageKeys,
  JobListTypes,
  JobGeoType,
  JobMode,
  realBookOrders,
  TruckTypes,
} from "../../constants/constants";
import { async } from "q";
import Assignments from ".././Procurement/Assignments";
export default {
  props: {
    loader: Boolean,
    dashboardData: Function,
    searchText: String,
    countryFilter: String,
    reset: Boolean,
    tabFilter: String,
    details: Object,
    tabListFilter: String,
    customerId: String,
    userId: String,
  },
  components: {
    DriverProfileForm,
    JobsProfile,
    DateRangePicker,
    PaginationButtons,
    pagination,
    activityLogs,
    Bidpopup,
    Responsepopup,
    Assignments,
  },

  data() {
    return {
      picker: {},
      dateRange: { startDate: "", endDate: "" },
      workingCountry: null,
      JobListTypes: [],
      subJobType: null,
      JobMode: [],
      subJobMode: null,
      JobGeoType: [],
      subGeoType: null,
      realBookOrders: [],
      subrealBookOrdres: null,

      disable: false,
      dateToday: moment(new Date()).format("YYYY/MM/DD HH:mm"),
      pages: 1,
      FilterDateBool: false,
      FilterEndDateBool: false,
      endDate: "",
      error: null,
      paginationURL: "",
      startDate: "",
      select: { status: "", abbr: "" },
      filtertype: { status: "", abbr: "" },
      jobStatus: [
        { status: "Active", abbr: "active" },
        { status: "Customer Rate Accepted", abbr: "customerRateAccepted" },
        { status: "Finding Drivers", abbr: "findingDrivers" },
        { status: "Driver Assigned", abbr: "driverAssigned" },
        { status: "In Transit", abbr: "inTransit" },
        { status: "Shipment Offloaded", abbr: "shipmentOffloaded" },
        { status: "Payment Pending", abbr: "paymentPending" },
        { status: "Payment Done", abbr: "paymentDone" },
        { status: "Completed", abbr: "completed" },
        { status: "Cancel", abbr: "cancel" },
      ],
      jobFilter: [
        { status: "Job Type", abbr: "jobType" },
        { status: "Job Mode", abbr: "jobMode" },
        { status: "Job Geo Type", abbr: "jobGeoType" },
        { status: "Account", abbr: "account" },
        // { status: "Booking", abbr: "Booking" },
      ],
      status: "",
      executive: "",
      search: "",
      activeJob: 0,
      cancelJobs: 0,
      completedJob: 0,
      totalJob: 0,
      totalDatas: 0,
      totalPages: 0,
      isNoSearchResult: false,
      loading: false,
      server: false,
      headers: customerJobList,
      items: [],
    };
  },

  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    this.paginationURL = `/dmsAdmin/custJobInqList?&type=job&custId=${this.customerId}&userId=${this.userId}`;
    this.JobListTypes = JobListTypes;
    this.JobGeoType = JobGeoType;
    this.JobMode = JobMode;
    this.realBookOrders = realBookOrders;
    this.subJobType = null;
    this.subJobMode = null;
    (this.subGeoType = null), (this.subrealBookOrdres = null);
    /* if (localStorage.getItem("paginationUrlJob") !== null) {
      this.updatePaginationUrl();
    } else {*/

    //}
  },
  methods: {
    clickCancel() {
      if (this.open) {
        // reset start and end
        let startDate = this.dateRange.startDate;
        let endDate = this.dateRange.endDate;
        this.start = startDate ? new Date(startDate) : null;
        this.end = endDate ? new Date(endDate) : null;
        // this.open = false
        this.togglePicker(false, true);
      }
    },
    updatePaginationUrl() {
      let tempUrl = localStorage.getItem("paginationUrlJob");
      let x = tempUrl.split("&");
      let y = x[0].split("=");
      y[1] = this.workingCountry;
      x[0] = y.join("=");
      tempUrl = x.join("&");
      this.paginationURL = tempUrl;
    },
    getValue() {},
    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    emptySub() {
      this.subJobMode = "";
      this.subJobType = "";
      this.subGeoType = "";
      this.subrealBookOrders = "";
    },
    getJobTypeWithCount(type) {
      var stringAir = "";
      var stringSea = "";
      var stringLand = "";
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["2"] > 0) {
          stringAir = "  Air-" + count["2"];
        }
        if (count["3"] > 0) {
          stringSea = "  Sea-" + count["3"];
        }
        if (count["1"] > 0) {
          stringLand = " Land-" + count["1"];
        }
        if (stringAir.length > 0 || stringSea.length > 0) {
          stringLand = stringLand.length > 0 ? stringLand + "," : stringLand;
        }
        if (stringSea.length > 0) {
          stringAir = stringAir.length > 0 ? stringAir + "," : stringAir;
        }
        return stringLand + "  " + stringAir + "  " + stringSea;
      } else {
        return "Land-1";
      }
    },
    getJobTypeValue(value) {
      switch (value) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    page(event) {
      this.pages = event;
    },

    searchHistory() {
      if (localStorage.getItem("searchText") !== null) {
      }
    },
    bidSuccess(event) {},
    refreshBid(event) {
      this.paginationURL = this.paginationURL;
      for (var i = 0; i < this.items.length; i++) {
        if (event.jobId === this.items[i].jobId) {
          this.items[i].isTransporterJob = true;
        }
      }
    },
    response(event) {},
    performSearch() {
      if (this.search) {
        this.getJobById(this.search);
      }
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    getDefaultUrl() {
      const { serverUrl } = this.constants;
      const { limit = 15, skip = 0 } = this;
      return `${serverUrl}/api/jobs/?limit=${limit}&skip=${skip}`;
    },
    goToProfile(jobId) {
      this.$eventBus.$emit("view-details", true);
      this.$router.push(`/jobs/${jobId}/view`);
    },
    getTime2(date) {
      return moment(date).format("ll");
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getTimes(time) {
      return moment.unix(time).format("ll");
    },
    getDate(startdate, time) {
      var test = moment.unix(startdate).format("YYYY/MM/DD") + " " + time;

      var diff = Math.abs(new Date(test) - new Date(this.dateToday)); // hours
      var minutes = Math.floor(diff / 1000 / 60);
      var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      var difference =
        (new Date(test).getTime() - new Date(this.dateToday).getTime()) / 1000;
      difference /= 60 * 60;

      return Math.abs(Math.round(difference));
    },

    getDateTime(time) {
      return moment.unix(time).format("DD/MM/YYYY hh:mm A");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Completed";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    async getCustomerById(customerId) {
      let url = custDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: customerId.toString(),
      };
      const response = await this.axios.post(
        this.constants.apiUrl + url,
        body,
        config
      );
      if (response.status == 200) {
        return response.data.data;
      } else {
        this.loading = false;
      }
    },
    getJobById(jobId) {
      this.loading = true;
      const url = `${this.constants.apiUrl}/api/jobs/detail/${jobId}`;
      this.axios.get(url).then(
        (response) => {
          const { status, nextURL = null, data } = response.data;
          const { jobInfo = null, totalJobs = 0 } = data;
          this.items = [jobInfo];
          this.loading = false;
          this.totalJobs = totalJobs;
        },
        (error) => {
          this.loading = false;
        }
      );
    },
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
      } else {
        this.$emit("off-load", false);
        this.loading = false;
      }
    },

    getLocation(...val) {
      return val ? val.reduce((val, curr) => val + ", " + curr) : "";
    },
    async callback({
      jobInfo,
      activeJobs,
      totalJobs,
      completedJobs,
      totalData,
      cancelJobs,
    }) {
      // localStorage.setItem("paginationUrl" ,this.paginationURL);
      this.items = jobInfo;
      this.loading = false;

      this.activeJob = activeJobs;
      this.cancelJobs = cancelJobs;
      this.completedJob = completedJobs;
      this.totalJob = totalJobs;
      this.totalDatas = totalData;
      this.$emit("totalDataJob", this.totalDatas);
      this.items.forEach((element) => {
        if (!element.isTransporterJob) element.isTransporterJob = false;
      });
      this.totalPages = Math.ceil(this.totalDatas / 15);
      this.$emit("customerJob-load", false);
      window.scrollTo(0, 0);
    },
    submit() {
      this.loading = true;
      let url = sendJobToTransport;
      // delete this.axios.defaults.headers.common["token"];
      // let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          // authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to update status";
        }
      );
    },
  },
};
</script>

<style lang="scss">
.daterangepicker.openscenter[data-v-267f4ee2] {
  position: absolute;
  top: 35px;
  left: 80%;
}
.reportrange-text[data-v-267f4ee2] {
  background: #fff;
  cursor: pointer;
  position: absolute;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  /* width: 100%; */
  width: 250px;
}

.drp-calendar {
  margin-right: 80px !important;
}

.daterangepicker.openscenter[data-v-267f4ee2] {
  right: auto;
  left: 62%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
// #start.v-menu__content{
//   min-width: 200px;
//    top: 200px !important;
//    left: 700px !important;
//    transform-origin: left top;
//    z-index: 8;

// }
// .end.v-menu__activator{
//   width:220px !important;

// }

.minHeight {
  min-height: 300px;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.change table.v-table tbody tr {
  background-color: forestgreen;
}
.viewDetail {
  cursor: pointer;
}
.job-detail {
  margin: auto 0;
}
.theme--light.menuable__content__active {
  min-width: 290px !important;
}
.text-center {
  text-align: center !important;
}

.date-picker {
  min-width: 100%;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.p-16 {
  padding: 0 16px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}

.colorchange {
  background-color: #fae3e3 !important;
}
.colorchangeyellow {
  background-color: #ffffe0 !important;
}
.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
</style>
