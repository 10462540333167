<template>
  <v-layout class="justify-center">
    <loading :active.sync="loading" />
    <button @click="dialog = true" class="track-ass-btn m-l-10">Assign</button>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">{{ confirmationTitle }}</span>

            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12 md12 pt-0 class="text-style">
                {{ message }}
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex pt-4 xs10>
                <v-text-field
                  class="pt-0 currencyTitle"
                  type="text"
                  label="Enter your reason"
                  color="black"
                  required
                  v-model="acceptanceReason"
                  :value="acceptanceReason"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" @click="accept()" :loading="loading">
              {{ confirmBtn }}
            </v-btn>
            <v-btn color="black darken-1" flat @click="close()">{{
              cancelBtn
            }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import Loading from "vue-loading-overlay";
import { acceptBid } from "../../constants/api-urls";
export default {
  props: {
    message: String,
    confirmationTitle: String,
    cancelBtn: String,
    confirmBtn: String,
    jobData: Object,
  },
  components: {
    Loading,
  },
  data: () => ({
    loading: false,
    valid: true,
    lazy: false,
    acceptanceReason: "",
    operationName: "",
    dialog: false,
    error: null,
  }),
  watch: {},
  created() {},
  methods: {
    close() {
      this.dialog = false;
      this.acceptanceReason = "";
    },
    accept() {
      this.loading = true;
      if (!this.acceptanceReason.trim()) {
        this.loading = false;
        this.error = "Enter the reason for accepting the bid";
        return;
      }

      let url = acceptBid;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.jobData.jobId,
        transporterId: this.jobData.transporterId,
        remarks: this.acceptanceReason,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = false;
          this.loading = false;
          this.$emit("refresh-list");
        },
        (error) => {
          if (error) {
            this.loading = false;

            this.error = error.response.data.message;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.m-t-5 {
  margin-top: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.text-style {
  font-size: 16px;
}
</style>
