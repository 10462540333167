<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-data-table
        id="customerListTable"
        hide-actions
        :headers="headers"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            {{ props.item.jobId }}
            <!-- <ActivityLogs :logs="props.item.logs" :id="props.item.inquiryId" /> -->
            <activityLogs
              :type="'inquiry'"
              :id="props.item.inquiryId.toString()"
            />
          </td>
          <td>{{ props.item.inquiryId }}</td>
          <td>{{ getJobTypeWithCount(props.item.jobType) || "Land" }}</td>
          <td v-if="props.item.userId">
            {{ capitalize(props.item.userId.companyName) }} <br />
            {{ props.item.userId.email || "N.A." }} <br />
            {{ props.item.userId.phoneNo }}
          </td>
          <td v-else>NA</td>
          <td v-if="props.item.bookingDate">
            {{ getTime3(props.item.bookingDate) }}
          </td>
          <td v-else>NA</td>
          <td>
            <salesAddressPopup :inquiryId="props.item.inquiryId" />
          </td>
          <td>{{ props.item.numberOfAssignmentsRequired || "N.A" }}</td>
          <td>
            {{ props.item.quotedPrice }}
            {{ props.item.customerCurrency || "" }} |
            {{ props.item.inquirySource || "N.A." }} <br /><a
              v-if="props.item.proof.length"
            >
              <ProofOfDelivery :deliveryProof="props.item.proof" />
            </a>
          </td>
          <td>{{ props.item.inquiryStatus }} <br /></td>

          <td class="">
            <v-btn
              v-if="
                props.item.inquiryStatus == 'Customer Rate Accepted' ||
                props.item.inquiryStatus == 'Low Credit Limit'
              "
              flat
              @click="goToJobProfile(props.item.jobId)"
              small
              color="primary"
              >VIEW DETAIL<v-icon>arrow_right</v-icon></v-btn
            >
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>

      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="paginationURL"
        :callback="callback"
        :type="0"
        :action="`sales`"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <!-- <ErrorBox :error="x.error" /> -->
    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
    >
      {{ x.error }}
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">
          {{ message }}
          <v-text-field
            type="text"
            label="Comments"
            required
            v-model="statusComments"
            :value="statusComments"
          />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="primary darken-1"
            :loading="loading"
            text
            @click.native="agree"
            >Yes</v-btn
          >
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import { customerSalesList } from "@/constants/datatable-headers.js";
//import PaginationButtons from "@/components/Pagination-New";
import activityLogs from "../Common/activityLogs";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import { debuglog } from "util";
import { StorageKeys } from "../../constants/constants";
import UpdateStatus from "../Sales/UpdateStatus";
import salesAddressPopup from "../Sales/salesAddressPopup";
import PaginationButtons from "@/components/Pagination/PaginationButtons";
import pagination from "@/components/Pagination/pagination";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val.value;
      this.loading = true;
      this.payload.workingCountry = this.workingCountry;
    });

    if (localStorage.getItem("current-customer-page-status")) {
      this.payload = JSON.parse(
        localStorage.getItem("current-customer-page-status")
      );
    }
    //  if (localStorage.getItem("payloadSales") !== null) {
    //   this.payload = JSON.parse(localStorage.payloadSales);

    // }
    //  else{
    this.payload = {
      skip: "0",
      limit: "15",
      workingCountry: this.workingCountry,
    };

    // }
    localStorage.removeItem("paginationUrlJob");
    this.paginationURL = `/dmsAdmin/custJobInqList?&type=${this.tabListFilter}&custId=${this.customerId}&userId=${this.userId}`;
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
  },
  components: {
    PaginationButtons,
    activityLogs,
    SuccessDialog,
    Loading,
    ErrorBox,
    UpdateStatus,
    salesAddressPopup,
    ProofOfDelivery,
    pagination,
  },
  props: {
    dashboardData: Function,
    searchText: String,
    loading: Boolean,
    countryFilter: String,
    reset: Boolean,
    startDateFilter: String,
    jobTypeFilter: String,
    endDateFilter: String,
    executiveFilter: String,
    tabListFilter: String,
    customerId: String,
    userId: String,
  },
  watch: {
    searchText: function (n, o) {
      //this.loading = false;
      if (n != "") {
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "searchText", n);

        //  this.payload = {
        //   skip: "0",
        //   limit: "15",
        //   searchText: n,

        // };
      }
      // else if(n==""){

      //      delete this.payload.searchText
      //    this.$set(this.payload );

      // }
      else {
        this.payload = {
          skip: "0",
          limit: "15",
          workingCountry: this.workingCountry,
        };
      }
    },
    jobTypeFilter: function (n, o) {
      this.loading = true;
      if (n != "") {
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "jobType", n);
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          workingCountry: this.workingCountry,
        };
      }
    },
    executiveFilter: function (n, o) {
      this.loading = true;
      if (n != "") {
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "salesExecutive", n);
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          workingCountry: this.workingCountry,
        };
      }
    },
    reset: function (n, o) {
      this.loading = true;

      if (n == true) {
        this.payload = {
          skip: "0",
          limit: "15",
          workingCountry: this.workingCountry,
        };
      }
    },
    startDateFilter: function (n, o) {
      this.loading = true;
      if (n != "") {
        this.payload.skip = "0";
        this.payload.limit = "15";
        this.$set(this.payload, "startDate", n);
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          workingCountry: this.workingCountry,
        };
      }
    },
    endDateFilter: function (n, o) {
      this.loading = true;
      if (n != "") {
        this.$set(this.payload, "endDate", n);
        this.payload.skip = "0";
        this.payload.limit = "15";
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          workingCountry: this.workingCountry,
        };
      }
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      workingCountry: null,
      title: null,
      message: null,
      dialog: false,
      statusComments: "",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      currentEnquiry: null,
      currentStatus: null,
      currentIndex: null,
      processing: false,
      componentKey: 0,
      search: "",
      isNoSearchResult: false,
      payload: {},
      totalPages: 0,
      pages: 1,
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      headers: customerSalesList,
      documentData: [],
      items: [],
    };
  },
  methods: {
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getJobTypeWithCount(type) {
      var stringAir = "";
      var stringSea = "";
      var stringLand = "";
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["2"] > 0) {
          stringAir = " Air-" + count["2"];
        }
        if (count["3"] > 0) {
          stringSea = " Sea-" + count["3"];
        }
        if (count["1"] > 0) {
          stringLand = " Land-" + count["1"];
        }
        if (stringAir.length > 0 || stringSea.length > 0) {
          stringLand = stringLand.length > 0 ? stringLand + "," : stringLand;
        }
        if (stringSea.length > 0) {
          stringAir = stringAir.length > 0 ? stringAir + "," : stringAir;
        }
        return stringLand + stringAir + stringSea;
      } else {
        return "Land-1";
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    page(event) {
      this.pages = event;
    },
    open(val, type, index) {
      this.currentStatus = type;
      this.currentEnquiry = val;
      this.currentIndex = index;
      this.statusComments = "";

      this.dialog = true;
      this.title = "Change Inquiry's Status";
      this.message = "Click Yes to update inquiry's status";
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      if (this.statusComments.trim()) {
        this.updateStatus(this.currentEnquiry, this.currentStatus);
        this.dialog = false;
      } else {
        this.x.error = "Please provide comments before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    updateStatus(value, status) {
      this.loading = true;
      let url = "/sales/changeStatus";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        inquiryId: value._id,
        inquiryStatus: status,
      };
      if (this.statusComments) {
        body.comments = this.statusComments.trim();
      }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.status === 200) {
            this.items[this.currentIndex].inquiryStatus = status;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
        }
      );
    },
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
      } else {
        this.$emit("off-load", false);
        this.loading = false;
      }
    },

    paginationPayload(event) {
      this.payload = event;
      localStorage.removeItem("current-enquiry-page-status");
    },
    openDocument(data) {
      this.getCustomer(data.userId);
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },

    editEnquiry(id) {
      localStorage.setItem(
        "current-enquiry-page-status",
        JSON.stringify(this.payload)
      );
      this.$router.push(`/inquiry/edit/${id}`);
    },

    getTime(date) {
      return moment(date).format("ll");
    },
    goToJobProfile(jobId) {
      this.$eventBus.$emit("view-details", true);
      this.$router.push(`/jobs/${jobId}/view`);
    },
    callback(res) {
      localStorage.setItem("payloadSales", JSON.stringify(this.payload));
      this.loading = false;
      this.items = res.list;
      this.$emit("totalDataSales", res.totalData);
      this.totalPages = Math.ceil(res.totalData / 15);
      for (var i = 0; i < this.items.length; i++) {
        this.items[i].createdOn = moment(
          String(this.items[i].registrationDate)
        ).format("MM/DD/YYYY hh:mm A");
      }
      // this.dashboardData(res);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.v-dialog {
  max-width: 400px !important;
}
.v-dialog .v-card {
  padding: 0 !important;
}
</style>
