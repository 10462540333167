<template>
  <v-layout class="pb-2 pr-2">
    <v-spacer />
    <v-btn
      right
      fab
      small
      :disabled="!prevUrl"
      :loading="prevLoading"
      class="mt-3 mx-2 pagination-back"
      @click.native="previous"
    >
      <v-icon>arrow_back</v-icon>
    </v-btn>
    <v-btn
      right
      fab
      small
      :disabled="!nextUrl"
      :loading="nextLoading"
      class="mt-3 mx-2 pagination-forward"
      @click.native="next"
    >
      <v-icon>arrow_forward</v-icon>
    </v-btn>
    <ErrorBox :error="x.error" />
  </v-layout>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
export default {
  created() {
    this.nextUrl = this.url;
    this.next();
  },
  components: {
    ErrorBox,
  },
  props: ["url", "callback", "action"],
  data() {
    return {
      prevUrl: null,
      prevLoading: false,
      nextUrl: null,
      nextLoading: false,
      x: {
        error: null,
      },
    };
  },
  watch: {
    url: {
      handler(val) {
        this.nextUrl = val;
        this.next();
      },
      deep: true,
    },
  },
  methods: {
    next() {
      if (this.nextUrl) {
        if (this.action == "job")
          localStorage.setItem("paginationUrlJob", this.nextUrl);
        if (this.action == "procure")
          localStorage.setItem("paginationUrlProcure", this.nextUrl);
        if (this.action == "user")
          localStorage.setItem("paginationUrlUser", this.nextUrl);
        this.$emit("pagination-load", true);
        this.nextLoading = true;
        let url = this.constants.serverUrl;
        if (
          this.action == "job" ||
          this.action == "user" ||
          this.action == "procure"
        ) {
          url = this.constants.apiUrl;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          this.axios.defaults.headers.common[
            "authorization"
          ] = `bearer ${token}`;
        }
        this.axios.get(url + this.nextUrl).then(
          (response) => {
            const { nextURL = null, data, previousURL = null } = response.data;
            this.nextUrl = nextURL;
            this.prevUrl = previousURL;

            this.callback(data);
            this.nextLoading = false;
            this.$emit("pagination-load", false);
          },
          (error) => {
            this.x.error = "Failed to Fetch Data";
            this.$emit("pagination-load", false);
          }
        );
      }
    },
    previous() {
      if (this.prevUrl) {
        if (this.action == "job")
          localStorage.setItem("paginationUrlJob", this.prevUrl);

        if (this.action == "procure")
          localStorage.setItem("paginationUrlProcure", this.prevUrl);
        if (this.action == "user")
          //localStorage.setItem('paginationUrlUser', this.prevUrl);
          //  if(this.action == 'user')
          //     localStorage.setItem('paginationUrlUser', this.prevUrl);
          this.$emit("pagination-load", true);
        this.prevLoading = true;
        let url = this.constants.serverUrl;
        if (
          this.action == "job" ||
          this.action == "user" ||
          this.action == "procure"
        ) {
          url = this.constants.apiUrl;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          this.axios.defaults.headers.common[
            "authorization"
          ] = `bearer ${token}`;
        }
        this.axios.get(url + this.prevUrl).then(
          (response) => {
            const { nextURL = null, data, previousURL = null } = response.data;
            this.nextUrl = nextURL;
            this.prevUrl = previousURL;

            this.callback(data);
            this.prevLoading = false;
            this.$emit("pagination-load", false);
          },
          (error) => {
            this.x.error = "Failed to Fetch Data";
            this.$emit("pagination-load", false);
          }
        );
      }
    },
  },
};
</script>

<style></style>
