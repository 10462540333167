<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-card-title class="bg-clr m-b-20">
        <v-btn fab flat @click.native="goback">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>
        {{ this.customerDetails.name }} ({{ this.customerDetails.companyName }})
        <br />
        {{ this.customerDetails.email }}
      </v-card-title>
      <v-tabs
        fixed-tabs
        class="m-b-20"
        background-color="darkorange"
        dark
        v-model="changeTabListing"
      >
        <v-tab :href="`#basicDetail`" @click="filterByTab('basicDetail')">
          Basic Details
        </v-tab>
        <v-tab :href="`#bookings`" @click="filterByTab('bookings')">
          Booking
          <div v-if="totalJobCount > 0">
            ({{ totalJobCount > 0 ? totalJobCount : "" }})
          </div>
        </v-tab>
        <v-tab :href="`#sales`" @click="filterByTab('sales')">
          Inquiry
          <div v-if="totalSalesCount > 0">
            ({{ totalSalesCount > 0 ? totalSalesCount : "" }})
          </div>
        </v-tab>
        <!-- <v-tab :href="`#creditLimit`" @click="filterByTab('creditLimit')">
                Credit Limit
                
                </v-tab> -->
      </v-tabs>

      <v-flex>
        <!--  <CustomerList
          :dashboardData="dashboardData"
          :searchText="search"
          :tabFilter="changeTabListing"
          :countryFilter="country"
          :reset="reset"
          :loader="loading"
          v-on:on-block="refreshActivity"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
        />-->
        <EditCustomer v-if="changeTabListing == 'basicDetail'" />
        <CustomerJobListing
          :searchText="search"
          :tabListFilter="changeTabListing"
          :countryFilter="country"
          :reset="reset"
          :loader="loading"
          v-on:on-block="refreshActivity"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
          v-on:totalDataJob="totalDataJob"
          :customerId="custId"
          :userId="userId"
          v-if="changeTabListing == 'bookings'"
        />
        <CustomerSaleListing
          :searchText="search"
          :tabListFilter="changeTabListing"
          :countryFilter="country"
          v-on:totalDataSales="totalDataSales"
          :reset="reset"
          :loader="loading"
          v-on:on-block="refreshActivity"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
          :customerId="custId"
          :userId="userId"
          v-if="changeTabListing == 'sales'"
        />
        <!-- <CreditLimit
         :searchText="search"
          :tabListFilter="changeTabListing"
        
          :reset="reset"
          :loader="loading"
          v-on:on-block="refreshActivity"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
         
          v-if="changeTabListing == 'creditLimit'"



        /> -->
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { EventBus } from "../../event-bus.js";
import Dashboard from "./Dashboard";
import CustomerList from "./CustomerList";
import CustomFilter from "./CustomFilter";
import CustomerJobListing from "./CustomerJobListing";
import CustomerSaleListing from "./CustomerSaleListing";
import EditCustomer from "./EditCustomer";
import CreditLimit from "./CreditLimit";
import moment from "moment";
import Loading from "vue-loading-overlay";
import { StorageKeys } from "../../constants/constants";
import { custDetails } from "../../constants/api-urls";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.invoicingCountry = key.value;
        }
      });
    }
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "customer-details") {
        this.$router.push(`/customers`);
      }
    });
    this.$eventBus.$on("customer-details", () => {
      this.bookingDetails = !this.bookingDetails;
    });

    this.getCustomer();
    (this.custId = this.$route.params.id),
      (this.userId = this.$route.params.userId),
      this.$eventBus.$on("close-job-profile", () => {
        this.dialog = false;
      });

    this.$eventBus.$on("customer-details-count", (data) => {
      if (data) {
        this.userId = data._id;
        this.bookingCount = data.totalBookings;
        this.inquiryCount = data.totalInquiry;
      }
    });

    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    localStorage.removeItem("paginationUrlJob");
  },

  mounted() {
    if (localStorage.getItem("changeBasicTabListing") != null) {
      this.changeTabListing = localStorage.changeBasicTabListing;
    } else {
      if (localStorage.getItem("changeTabListing") != null) {
        this.changeTabListing = localStorage.changeTabListing;
      } else {
        this.changeTabListing = "bookings";
      }
    }
  },
  components: {
    Dashboard,
    CustomerList,
    CustomFilter,
    Loading,
    CustomerJobListing,
    CustomerSaleListing,
    EditCustomer,
    CreditLimit,
  },

  data: () => ({
    search: "",
    country: "",
    bookingDetails: false,
    dashboard: {
      today: 0,
      total: 0,
    },
    active: "0",
    totalJobCount: "",
    totalSalesCount: "",
    dialog: false,
    reset: false,
    loading: true,
    changeTabListing: "bookings",
    customerDetails: {},
    custId: "",
    userId: "",
    bookingCount: null,
    inquiryCount: null,
  }),
  methods: {
    goback() {
      this.$router.go(-1);
    },
    getCustomer() {
      this.loading = true;
      let url = custDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: this.$route.params.id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.userId = response.data.data._id;
          this.customerDetails = response.data.data;
          this.custId = this.customerDetails.userId.toString();
        },
        (error) => {
          this.x.error = "Failed to Fetch User Details";
        }
      );
    },
    filterByTab(value) {
      localStorage.setItem("changeTabListing", value);
      this.changeTabListing = value;
    },
    refreshActivity() {
      this.reset = true;
    },
    startLoad() {
      this.loading = true;
    },
    totalDataJob(e) {
      this.totalJobCount = e;
    },
    totalDataSales(e) {
      console.log(e);
      this.totalSalesCount = e;
    },
    stopLoad() {
      this.loading = false;
    },
    resetCustomer() {
      this.reset = true;
      this.search = "";
    },
    dashboardData(event) {
      this.dashboard.total = event.totalData;
      this.dashboard.today = event.todayData;
      this.loading = false;
    },
    searchText(event) {
      this.search = event;
      this.reset = false;
    },
    countryFilter(event) {
      this.country = event;
      this.reset = false;
    },
  },
};
</script>

<style scoped>
.m-b-20 {
  margin-bottom: 20px !important;
}
</style>
