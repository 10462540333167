<template>
  <v-dialog v-model="toShow" persistent max-width="700">
    <v-form
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      onSubmit="return false;"
    >
      <v-card>
        <v-card-title class="headline bg-clr" style="padding: 16px !important">
          Contact Details
          <v-spacer />
          <div @click="closeDialog">
            <v-icon class="pointer">close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-layout align-baseline row wrap>
              <v-flex xs12 class="py-1 pr-2">
                <div class="muted caption">Name*</div>
                <v-text-field
                  label="Name*"
                  type="text"
                  disabled
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  v-model="contacts.name"
                  :value="contacts.name"
                />
              </v-flex>
              <v-flex xs12 class="py-1 pr-2">
                <div class="muted caption">Email*</div>
                <v-text-field
                  label="Email*"
                  single-line
                  disabled
                  type="text"
                  class="pt-0 currencyTitle"
                  v-model="contacts.email"
                  :value="contacts.email"
                  :rules="[rules.emailRules]"
                />
              </v-flex>
              <v-flex xs12 md4>
                <div class="muted caption">Country Code*</div>
                <!-- <v-select
                  style="background-color: white !important"
                  :rules="[rules.required]"
                  :close-on-content-click="true"
                  attach
                  disabled
                  class="pt-0 currencyTitle"
                  :items="countryCodes"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  single-line
                  v-model="contacts.countryCode"
                  label="Country Code*"
                ></v-select> -->
                <v-autocomplete
                  style="background-color: white !important"
                  :rules="[rules.required]"
                  :close-on-content-click="true"
                  attach
                  :items="countryCodes"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="contacts.countryCode"
                  label="Country Code*"
                ></v-autocomplete>
              </v-flex>

              <!-- <v-flex xs12 md4>
                        <v-text-field
                                    label="Country Code *"
                                      single-line
                                    v-model="contacts.countryCode"
                                    :rules="[rules.countryCode]"
                                    required
                                />
                    </v-flex> -->
              <v-flex xs12 md8 class="">
                <div class="muted caption">Phone Number*</div>
                <v-text-field
                  label="Phone Number*"
                  type="number"
                  v-model="contacts.mobile"
                  :value="contacts.mobile"
                  single-line
                  class="pt-0 currencyTitle"
                  disabled
                  :rules="[rules.phoneNumber]"
                />
              </v-flex>
              <v-flex xs12 class="py-1 pr-2">
                <div class="muted caption">Designation*</div>
                <v-text-field
                  label="Designation*"
                  single-line
                  type="text"
                  disabled
                  :rules="[rules.noWhiteSpace, rules.required]"
                  v-model="contacts.designation"
                  class="pt-0 currencyTitle"
                  :value="contacts.designation"
                />
              </v-flex>

              <div class="red--text" v-if="error !== null">{{ error }}</div>
              <v-spacer />
            </v-layout>
            <v-layout>
              <v-spacer />

              <v-spacer />
            </v-layout>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.min.css";
import ErrorBox from "@/components/Common/ErrorBox";
import { StorageKeys, countries } from "../../constants/constants";

export default {
  created() {
    this.countryCodes = countries;
  },
  components: {
    ErrorBox,
    Loading,
  },
  props: {
    toShow: Boolean,
    contacts: Object,
    onclose: Function,
  },
  data: () => ({
    countryCodes: [],
    valid: true,
    lazy: false,
    model: [],
    error: null,
    loading: false,
    name: null,
    email: null,
    mobile: null,
    countryCode: null,
    designation: null,
    rules: {
      required: (value) => !!value || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "Enter valid value.",
      emailRules: (v) =>
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
          v
        ) || "E-mail must be valid",
      countryCode: (v) =>
        /^(\+?\d{1,3}|\d{1,4})$/.test(v) || "Please Enter Valid Country Code.",
      phoneNumber: (v) =>
        /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
      // phoneNumber: v =>
      //     /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
      //     "Please Enter Valid Mobile No."
    },
  }),
  computed: {
    hasError() {
      return this.error !== null;
    },
  },
  watch: {
    toShow(val) {
      if (!val) {
        this.closeDialog();
      }
    },
    contacts(val) {
      this.name = val.name;
      this.email = val.email;
      this.mobile = val.mobile;
      this.countryCode = val.countryCode;
      this.designation = val.designation;
    },
  },
  methods: {
    closeDialog() {
      this.error = "";
      // this.name = this.email = this.mobile = this.countryCode = this.designation =null;
      this.$eventBus.$emit("view-contact-assign-close");
    },
    destroyed() {
      this.name =
        this.email =
        this.mobile =
        this.countryCode =
        this.designation =
          null;
    },

    addContactDone() {
      if (this.$refs.form.validate()) {
        var obj = {
          name: this.contacts.name,
          email: this.contacts.email,
          mobile: this.contacts.mobile,
          designation: this.contacts.designation,
          countryCode: this.contacts.countryCode,
        };
        this.loading = false;
        this.$eventBus.$emit("add-contact-assign-done", obj);
      } else {
        this.error = "Please fill all required fields in above form !";
      }
    },
  },
};
</script>

<style lang="scss">
.hoverable {
  &:hover {
    color: rgb(255, 181, 21) !important;
  }
}
.text-right {
  text-align: right !important;
}
</style>
