<template>
  <v-container row justify-end>
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-show="loading"
    ></v-progress-linear>
    <v-card color="white">
      <v-card-title primary-title class="bg-clr">
        <v-btn fab flat icon class="nospace" @click.native="close">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <span class="headline">&nbsp;Credits </span>
      </v-card-title>
      <v-card-text class="content-border">
        <v-layout>
          <v-flex class="pr-3" xs12 md3>
            <v-form ref="form1" v-model="valid" :lazy-validation="lazy">
              <div class="muted caption">
                Credit limit
                <button
                  v-if="edit"
                  v-permissions="'edit-credit-limit'"
                  @click="checkEdit()"
                  type="button"
                  class="ass-btn"
                >
                  Edit
                </button>
                <button
                  v-if="!edit"
                  v-permissions="'edit-credit-limit'"
                  @click="checkSave()"
                  type="button"
                  :disabled="loading"
                  class="ass-btn"
                >
                  Save
                </button>
              </div>
              <div style="word-break: break-all" v-if="edit && creditLimit">
                {{ creditLimit }} {{ currency }}
              </div>
              <div v-else-if="edit && !creditLimit">0 {{ currency }}</div>
              <v-text-field
                label="Credit limit"
                class="pt-0 currencyTitle"
                v-model="creditLimit"
                v-else
                :suffix="currency"
                single-line
                type="number"
                :rules="[rules.required, rules.number]"
              />
            </v-form>
          </v-flex>

          <v-flex class="pr-3" xs12 md3>
            <div class="muted caption">Credit Used</div>
            <div style="word-break: break-all" v-if="creditUsed">
              {{ creditUsed }} {{ currency }}
            </div>
            <div v-else>0 {{ currency }}</div>
          </v-flex>
          <v-flex class="pr-3" xs12 md3>
            <div class="muted caption">Credit Remaining</div>
            <div style="word-break: break-all" v-if="creditRemaining">
              {{ creditRemaining }} {{ currency }}
            </div>
            <div v-else>0 {{ currency }}</div>
          </v-flex>

          <v-flex class="pr-3" xs12 md3>
            <v-form ref="form" v-model="validTime" :lazy-validation="lazy">
              <div class="muted caption">
                Credit Time Period
                <button
                  v-if="editTime"
                  v-permissions="'edit-credit-limit'"
                  @click="checkEditTime()"
                  type="button"
                  class="ass-btn"
                >
                  Edit
                </button>
                <button
                  v-if="!editTime"
                  v-permissions="'edit-credit-limit'"
                  @click="checkSaveTime()"
                  type="button"
                  class="ass-btn"
                  :disabled="loading"
                >
                  Save
                </button>
              </div>
              <div style="word-break: break-all" v-if="editTime && invoiceDay">
                {{ invoiceDay }} days
              </div>
              <div v-else-if="editTime && !invoiceDay">0</div>
              <v-text-field
                label="Credit time period"
                class="pt-0 currencyTitle"
                v-else
                v-model="invoiceDay"
                typr="number"
                single-line
                :rules="[rules.required, rules.day]"
              />
            </v-form>
          </v-flex>
        </v-layout>
        <v-layout pt-4>
          <v-flex xs2 class="main-heading">Credit History</v-flex>
          <v-flex xs10 pr-3><v-divider class="my-5" /></v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <span class="muted"></span>
            <v-menu
              ref="FilterDateBool"
              lazy
              v-model="FilterDateBool"
              :close-on-content-click="false"
              transition="scale-transition"
              full-width
              :nudge-right="40"
              min-width="290px"
              :return-value.sync="startDate"
            >
              <v-text-field
                slot="activator"
                :label="'Start date'"
                required
                v-model="startDate"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker
                v-model="startDate"
                class="minHeight"
                @change="
                  $refs.FilterDateBool.save(startDate);
                  startDateFilter();
                "
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2 v-if="startDate" class="reset pt-2">
            <v-btn
              class="reset-btn-color"
              flat
              style="margin-top: 14px !important"
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-data-table
          id="customerListTable"
          hide-actions
          :headers="headers"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <td style="width: 20%">{{ getTime3(props.item.updatedAt) }}</td>
            <td style="width: 20%">{{ props.item.comments }}</td>
            <td style="width: 20%">{{ props.item.editedBy.name }}</td>
            <td
              v-if="props.item.amount || props.item.amount != null"
              style="width: 20%"
            >
              {{ props.item.amount }} {{ currency }}
            </td>
            <td v-else>-</td>
          </template>

          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <span class="page-count-span totalpages"
          >Total Pages - {{ totalPages }}</span
        >
        <PaginationButtons
          :url="`/dmsAdmin/creditHistory`"
          :payload="payload"
          :callback="callback"
          :componentKey="componentKey"
          v-on:pagination-payload="paginationPayload"
        />
      </v-card-text>
    </v-card>
    <success-dialog
      content="Customer Edited successful"
      :show="x.registerSuccess"
      :onclose="closeRegistration"
    />
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </v-container>
</template>

<script>
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import moment from "moment";
import {
  StorageKeys,
  countries,
  NatureOfCargosAdd,
} from "../../constants/constants";
import {
  custDetails,
  editCreditLimit,
  editInvoiceDay,
} from "../../constants/api-urls";
import ConfirmationDialogVue from "@/components/Common/ConfirmationDialog.vue";
export default {
  components: {
    SuccessDialog,
    PaginationButtons,
  },
  props: {},
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.invoicingCountry = key.value;
          this.currency = key.currency;
          this.payload = {
            skip: "0",
            limit: "15",
            userId: this.$route.params.userId,
          };
        }
      });
    }
    EventBus.$on("countryChanged", (val) => {
      this.invoicingCountry = val.value;
    });
    this.countryCodes = countries;
    this.getCustomer();
  },
  data() {
    return {
      startDate: "",
      totalPages: 0,
      FilterDateBool: false,
      items: [],
      payload: {},

      headers: [
        {
          text: "Date",
          sortable: false,
          value: "date",
          class: "table-header-item",
        },

        {
          text: "Description",
          sortable: false,
          value: "description",
          class: "table-header-item",
        },
        {
          text: "Updated By",
          sortable: false,
          value: "updated by",
          class: "table-header-item",
        },
        {
          text: "Amount",
          sortable: false,
          value: "executive",
          class: "table-header-item",
        },
      ],
      readonly: true,
      currency: "",
      edit: true,
      editTime: true,
      creditLimit: "",
      invoiceDay: "",
      creditUsed: "",
      creditRemaining: "",
      multipleCountry: "",
      countryCodes: [],
      invoicingCity: null,
      invoicingCountry: "",
      valid: true,
      validTime: true,
      lazy: false,
      model: [],
      processing: false,
      componentKey: 0,
      loading: false,
      search: "",
      isNoSearchResult: false,
      dateToday: moment(new Date()).format("YYYY-MM-DD"),

      x: {
        loading: false,
        registerSuccess: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        // phoneNumber: (v) =>
        //   /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        //   "Please Enter Valid Phone No.",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
        counter: (value) =>
          (value != null && value.trim().length <= 20) ||
          "Number should contain maximum 20 characters.",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Value",
        requiredLocation: (value) =>
          (!!value && !!value.length) || "This field is required.",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
        day: (v) => /^[0-9]*$/.test(v) || "Please enter valid days",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getString(amount) {
      return amount.toString();
    },
    async checkEdit() {
      this.operationName = "edit-credit-limit";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.edit = !this.edit;
      } else {
        return;
      }
    },
    async checkSave() {
      this.operationName = "edit-credit-limit";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.editCreditLimit();
      } else {
        return;
      }
    },
    async checkEditTime() {
      this.operationName = "edit-credit-limit";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.editTime = !this.editTime;
      } else {
        return;
      }
    },
    async checkSaveTime() {
      this.operationName = "edit-credit-limit";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.editDay();
      } else {
        return;
      }
    },
    startDateFilter() {
      if (this.startDate != "") {
        this.payload = {
          skip: "0",
          limit: "15",
          userId: this.$route.params.userId,
          startDate: this.startDate,
        };
      } else {
        this.payload = {
          skip: "0",
          limit: "15",
          userId: this.$route.params.userId,
        };
      }
    },
    reset() {
      if (!this.startDate) {
        return;
      } else {
        this.startDate = "";
        this.payload = {
          skip: "0",
          limit: "15",
          userId: this.$route.params.userId,
        };
      }
    },
    getTime3(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    callback(res) {
      //localStorage.setItem("payloadCustomer",JSON.stringify(this.payload));
      this.loading = false;
      this.items = res.list;

      this.totalPages = Math.ceil(res.totalData / 15);
      for (var i = 0; i < this.items.length; i++) {
        this.items[i].createdOn = moment(
          String(this.items[i].registrationDate)
        ).format("MM/DD/YYYY hh:mm A");
      }
      window.scrollTo(0, 0);
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },
    paginationPayload(event) {
      this.payload = event;
    },

    close() {
      this.$router.go(-1);
    },
    getCustomer() {
      this.loading = true;
      let url = custDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: this.$route.params.id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;

          this.creditLimit = response.data.data.creditLimit;
          this.invoiceDay = response.data.data.invoiceDay;
          this.creditUsed = response.data.data.creditUsed;
          this.creditRemaining = response.data.data.creditRemaining;
        },
        (error) => {
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },
    editCreditLimit() {
      if (this.$refs.form1.validate()) {
        this.loading = true;
        let url = editCreditLimit;
        delete this.axios.defaults.headers.common["token"];
        let body = {
          userId: this.$route.params.userId,
          creditLimit: parseInt(this.creditLimit).toString(),
        };
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.loading = false;
            this.edit = !this.edit;
            this.componentKey += 1;
            this.getCustomer();
          },
          (error) => {
            this.loading = false;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        this.x.error = "Please fill all required fields in above form !";
      }
    },
    editDay() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = editInvoiceDay;
        delete this.axios.defaults.headers.common["token"];
        let body = {
          userId: this.$route.params.userId,
          invoiceDay: parseInt(this.invoiceDay).toString(),
        };
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.loading = false;
            this.editTime = !this.editTime;
            this.componentKey += 1;
            this.getCustomer();
          },
          (error) => {
            this.loading = false;
            this.x.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.expiry {
  width: 100%;
}
.main-heading {
  padding-top: 25px;
  font-size: 25px;
}
.menu,
.menu__content {
  background: white !important;
}

.jbtn-file {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.jbtn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.margin-bottom20 {
  margin-bottom: 20px;
}
.marginTop20 {
  margin-top: 20px;
}
.font16 {
  font-size: 16px;
}
</style>
<style scoped>
.doc-image {
  float: right;
  width: 40%;
}
.ass-btn {
  text-decoration: none;
  color: darkorange;
  font-size: 10px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid darkorange;
}
.grey-btn {
  background-color: grey !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}
.widthbtn {
  width: 196px;
}
.minHeight {
  min-height: 300px;
}
</style>
