<template>
  <div>
    <span class="muted caption">Update Status</span>
    <v-select
      :items="items"
      v-model="model"
      v-on:change="updateStatus"
      class="pt-0 currencyTitle"
      single-line
    >
    </v-select>
  </div>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
export default {
  props: ["onselect", "inquiryId"],
  data() {
    return {
      items: ["Delay", "Rate accepted", "Lead lost"],
      model: null,
    };
  },
  watch: {
    model(val) {
      if (this.onselect) {
        this.onselect(this.model);
      }
    },
  },
  methods: {
    updateStatus: function() {
      let url = "/sales/changeStatus";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        inquiryId: this.inquiryId,
        inquiryStatus: this.model,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {},
        (error) => {}
      );
    },
  },
};
</script>

<style>
.currencyTitle {
  margin-top: -2px !important;
}
</style>
