import { render, staticRenderFns } from "./CreditLimit.vue?vue&type=template&id=466dab3b&scoped=true&"
import script from "./CreditLimit.vue?vue&type=script&lang=js&"
export * from "./CreditLimit.vue?vue&type=script&lang=js&"
import style0 from "./CreditLimit.vue?vue&type=style&index=0&id=466dab3b&scoped=true&lang=scss&"
import style1 from "./CreditLimit.vue?vue&type=style&index=1&id=466dab3b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466dab3b",
  null
  
)

export default component.exports