<template>
  <div class="log">
    <button
      v-permissions="'view-details-jobs'"
      @click.stop="dialog = false"
      @click="getAssignments"
      title="View Address"
      target="_blank"
      class="view-ass-btn"
    >
      View
    </button>

    <v-dialog v-model="dialog" max-width="50%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>View Address Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="spacerClass">
            <v-card-text class="nospace">
              <v-data-table
                id="jobsTable"
                hide-actions
                :headers="headers"
                :items="items"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.index + 1 }}</td>
                  <td>
                    {{ getJobType(props.item.jobType) }}
                  </td>
                  <td>{{ props.item.sourceLocation }}</td>
                  <td>{{ props.item.destinationLocation }}</td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
    >
      {{ x.error }}
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import { salesAddressList } from "@/constants/datatable-headers.js";
import ErrorBox from "@/components/Common/ErrorBox";
import { StorageKeys } from "../../constants/constants";
import { salesSectionApiUrl } from "../../constants/api-urls";
export default {
  data() {
    return {
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      headers: salesAddressList,
    };
  },
  props: {
    inquiryId: String,
  },
  created() {},
  components: {
    ErrorBox,
  },
  methods: {
    getJobType(type) {
      switch (type) {
        case 1:
          return "Land";
        case 2:
          return "Air";
        case 3:
          return "Sea";
        default:
          return "Land";
      }
    },
    getTime(date) {
      return moment(date).format("YYYY-MM-DD hh:mm A");
    },

    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";
        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    getAssignments() {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      this.dialog = true;
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let url = salesSectionApiUrl.viewInquiryAddress;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          inquiryId: this.inquiryId.toString(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.dialog = true;
            this.items = response.data.data[0].subJobList;

            this.processing = false;
          },
          (error) => {
            this.x.error = "Failed to Fetch Assignments";
            this.processing = false;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.spacerClass {
  max-height: 400px;
}
</style>
