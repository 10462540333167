<template>
  <div class="log pb-2">
    <v-btn
      class="ma-2"
      outline
      color="orange"
      click.stop="dialog = false"
      @click="dialog = true"
    >
      + Add Quoted Price
    </v-btn>

    <v-dialog v-model="dialog" max-width="30%" persistent>
      <v-form
        ref="form"
        onsubmit="return false;"
        v-model="valid"
        lazy-validation
      >
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Add Quote Price</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout pt-4>
                  <v-flex xs10 pt-2>
                    <v-text-field
                      label=" Driver Quoted Price "
                      type="number"
                      min="1"
                      max="1000000"
                      :suffix="currency"
                      v-model="amount"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex pt-2 xs11>
                    <v-text-field
                      rows="2"
                      no-resize
                      label="Enter comments"
                      v-model="comments"
                      class=" "
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-snackbar
                :timeout="3000"
                bottom
                color="red darken-2"
                v-model="x.error"
                class="white--text"
                v-if="x.error"
                >{{ x.error }}</v-snackbar
              >
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                :disabled="processing"
                flat
                @click="close"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                :disabled="processing"
                flat
                @click="Submit()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";

import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys } from "../../constants/constants";
import { procurement } from "../../constants/api-urls";
export default {
  name: "DriverQuote",
  data() {
    return {
      AssignmentId: [],
      customerStatus: "",
      ids: "",
      currency: "",
      valid: true,
      lazy: false,
      type: "",
      comments: "",

      x: {
        error: null,
        success: false,
      },
      amount: null,
      comments: null,
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    assId: Number,
    currency: String,
  },
  created() {},
  components: {
    SuccessDialog,
  },
  methods: {
    close() {
      this.dialog = false;
      this.document = [];
      this.comments = "";
      this.amount = "";

      this.$refs.form.resetValidation();
    },
    Submit() {
      let url = procurement.sendQuotePriceToSales;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      var body = {
        assignmentId: this.$props.assId.toString(),
        quotedPriceByOps: this.amount.toString(),
        comment: this.comments,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.close();
        },
        (error) => {}
      );
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.document-link {
  text-decoration: none;
}
.ttt button select {
  border: none !important;
}
.imageClose {
  position: absolute;
  width: 15px;
  bottom: 80px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  color: #fff !important;
  line-height: 15px;
  top: -6px;
  left: 2px;
}

.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: inline-block;
  position: relative;
}
.thumb {
  width: 80px;
  height: 80px;
}
</style>
