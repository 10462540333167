<template>
  <div class="log">
    <button
      @click.stop="checkInternet()"
      v-if="!address.isTransporterJob || !address.isDriverJob"
      class="track-ass-btn min_width_89"
    >
      Send for Bid
    </button>

    <v-dialog
      v-model="dialog"
      max-width="70%"
      max-height="96%"
      style="height: 100% !important; max-height: 100% !important"
      persistent
    >
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        height="100%"
        onSubmit="return false;"
      >
        <v-card class="comments-view" style="height: 100% !important">
          <v-toolbar fixed flat>
            <v-toolbar-title
              >Place a bid
              <span>(Job Id -{{ this.address.jobId }})</span></v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class="bid-pop spacerclass scrollDialog">
            <v-card-text class="nospace">
              <v-layout>
                <v-flex xs6 md7>
                  <v-layout>
                    <v-flex xs12 md12 pl-4>
                      <span class="bidheading">Select to Bid</span>
                      <v-radio-group
                        style="padding-top: 5px !important;
                               margin-top: 5px; !important"
                        v-model="radios"
                        row
                        class="customRadio"
                        :mandatory="false"
                      >
                        <v-radio
                          class="customLabel icon2"
                          label="Transporter"
                          @change="
                            searchTypeTransporter($event);
                            isSearchResult = false;
                          "
                          color="orange"
                          value="Transporter"
                        ></v-radio>
                        <v-radio
                          class="customLabel icon1"
                          label="Owner Driver"
                          value="Driver"
                          @change="
                            searchTypeOwner($event);
                            isSearchResult = false;
                          "
                          color="orange"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-flex>
                    <v-checkbox
                      style="padding-top: 20px  !important;margin-left:25px;
                             margin-top: 0px; !important"
                      v-model="isSelect"
                      :label="
                        radios == 'Transporter'
                          ? 'Manually send bid to the transporters'
                          : 'Manually send bid to the owner drivers'
                      "
                      value="singleSelect"
                      class="message"
                    ></v-checkbox>
                    <v-radio-group
                      v-if="this.selectedids && this.selectedids.length"
                      style="margin-left:25px;
                             margin-top: 0px; !important"
                      v-model="wayToNotify"
                      row
                      :mandatory="false"
                    >
                      <v-radio
                        label="Email"
                        color="orange"
                        value="EMAIL"
                      ></v-radio>
                      <v-radio label="SMS" value="SMS" color="orange">
                      </v-radio>
                      <v-radio label="Both" value="BOTH" color="orange">
                      </v-radio>
                    </v-radio-group>

                    <v-layout v-if="isSelect == 'singleSelect'">
                      <v-flex xs6 md12 pr-3 pl-4>
                        <v-text-field
                          height="55px"
                          class="gap"
                          :label="
                            radios == 'Transporter'
                              ? 'Search Transporter Name,Id...'
                              : 'Search Driver Name,Id...'
                          "
                          outline
                          single-line
                          v-model="search"
                          append-icon="search"
                          @keyup.enter="searchAnything"
                          @click:append="searchAnything"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 md4>
                        <v-btn
                          flat
                          class="reset-btn-color"
                          prepend-icon
                          @click="reset()"
                          color="orange darken-1"
                        >
                          Reset
                          <v-icon color="orange">donut_large</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs10
                    md10
                    pl-4
                    pt-4
                    v-if="radios == 'Driver' && !isSelect"
                  >
                    <p style="font-size: 60px">
                      {{ totalListdata
                      }}<span class="extraTextStyle">Owner Drivers</span>
                    </p>
                  </v-flex>
                  <v-layout class="pl-4" v-if="radios == 'Driver' && !isSelect">
                    <p
                      style="font-size: 16px; font-size: 16px; color: lightgray"
                    >
                      Preferred Location : {{ location }} , Truck type :
                      {{ getText(TruckTypes, truckType) }}
                    </p>
                  </v-layout>
                  <v-flex
                    pt-4
                    xs10
                    md10
                    pl-4
                    v-if="radios == 'Transporter' && !isSelect && totalListdata"
                  >
                    <p style="font-size: 60px">
                      {{ totalListdata
                      }}<span class="extraTextStyle"> Transporters</span>
                    </p>
                  </v-flex>
                  <v-layout v-if="isSelect == 'singleSelect'">
                    <v-flex xs12 pr-4 v-if="radios == 'Driver'">
                      <v-spacer class="spacerBid" ref="tableList">
                        <v-data-table
                          id="jobsTable"
                          :headers="headers"
                          :items="items"
                          hide-actions
                          item-key="driverId"
                          class="elevation-1"
                        >
                          <template slot="items" slot-scope="props">
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.driverId }}</td>
                            <td>{{ props.item.completeAssignCount }}</td>
                            <td style="width: 20%">
                              <v-layout>
                                <v-checkbox
                                  class="align-center justify-center"
                                  v-model="props.item.selected"
                                  @change="
                                    checkSelected(
                                      props.item.selected,
                                      props.item.driverId,
                                      props.index,
                                      props.item.name
                                    )
                                  "
                                  primary
                                  hide-details
                                ></v-checkbox>
                              </v-layout>
                            </td>
                          </template>
                        </v-data-table>
                      </v-spacer>
                    </v-flex>
                    <v-flex xs12 pr-4 v-if="radios == 'Transporter'">
                      <v-spacer class="spacerBid" ref="tableList">
                        <v-data-table
                          id="jobsTable"
                          :headers="Transporterheaders"
                          :items="items"
                          hide-actions
                          item-key="transporterId"
                          class="elevation-1"
                        >
                          <template slot="items" slot-scope="props">
                            <td>{{ props.item.transporterName }}</td>
                            <td>{{ props.item.transporterId }}</td>
                            <td style="width: 30%">
                              <v-checkbox
                                class="align-center justify-center"
                                v-model="props.item.selected"
                                @change="
                                  checkSelected(
                                    props.item.selected,
                                    props.item.transporterId,
                                    props.index,
                                    props.item.transporterName
                                  )
                                "
                                primary
                                hide-details
                              ></v-checkbox>
                            </td>
                          </template>
                        </v-data-table>
                      </v-spacer>
                    </v-flex>
                  </v-layout>
                  <PaginationButtons
                    v-if="isSelect == 'singleSelect'"
                    :url="`/newDriverFlow/BidTransporterDriverList`"
                    :payload="payload"
                    :callback="callback"
                    :componentKey="componentKey"
                    v-on:pagination-payload="paginationPayload"
                  />
                </v-flex>
                <v-divider vertical></v-divider>
                <v-flex xs6 md5 class="pl-3">
                  <v-layout>
                    <v-flex v-if="Rate">
                      <div class="subbidheading">Job Price</div>
                      <div class="heading">
                        <v-text-field
                          slot="activator"
                          label="Job Price"
                          readonly
                          single-line
                          class="pt-0 currencyTitle"
                          v-model="Rate"
                          disabled
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <div class="subbidheading">Enter Bid Price</div>
                      <div class="heading">
                        <v-text-field
                          slot="activator"
                          label="Enter Bid Price"
                          :suffix="currency"
                          class="pt-0 currencyTitle"
                          required
                          single-line
                          type="number"
                          v-model="Price"
                          :rules="[rules.required, rules.number]"
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex v-if="radios == 'Driver'">
                      <div class="subbidheading">Price Per Assignment</div>
                      <div class="heading">
                        <v-text-field
                          slot="activator"
                          label="Price Per Assignment"
                          readonly
                          single-line
                          class="pt-0 currencyTitle"
                          v-model="AssignmentRate"
                          disabled
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex class="pt5">
                      <div class="subbidheading">Start Date of the job</div>
                      <div class="heading">
                        <v-text-field
                          slot="activator"
                          label="Start Date of the job"
                          readonly
                          single-line
                          class="pt-0 currencyTitle"
                          disabled
                          v-model="pickUpTimeDate"
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <div class="subbidheading">Bid Expiry Date</div>
                      <div class="heading">
                        <v-menu
                          ref="jobStartDateBool"
                          lazy
                          v-model="jobStartDateBool"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          full-width
                          :nudge-right="40"
                          min-width="290px"
                          :return-value.sync="jobStartDatePicker"
                        >
                          <v-text-field
                            slot="activator"
                            label="Bid Expiry Date"
                            v-model="jobStartDatePicker"
                            required
                            single-line
                            class="pt-0 currencyTitle"
                            :rules="[rules.required]"
                            readonly
                          ></v-text-field>
                          <v-date-picker
                            v-model="jobStartDatePicker"
                            :min="dateToday"
                            class="minHeight"
                            @change="
                              $refs.jobStartDateBool.save(jobStartDatePicker)
                            "
                            no-title
                            scrollable
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex class=" " style="position: relative">
                      <div class="subbidheading">Bid Expiry Time</div>
                      <div class="heading">
                        <vue-timepicker
                          class="fonttime pt-0 currencyTitle"
                          :minute-interval="30"
                          v-model="pickupTime"
                          input-width="26em"
                          placeholder="Bid Expiry Time"
                          close-on-complete
                        ></vue-timepicker>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex pt-1>
                      <div class="subbidheading">Set Milestone</div>
                      <v-select
                        v-model="milestone"
                        :items="milestoneItems"
                        item-text="pickupLocation"
                        item-value="_id"
                        class="pt-0 currencyTitle"
                        label="Select a milestone"
                        @change="getChecklist()"
                        persistent-hint
                        :rules="[rules.required]"
                        single-line
                      >
                        <template slot="item" slot-scope="data"
                          >{{ data.item.pickupLocation }} -
                          {{ data.item.dropOffLocation }}</template
                        >
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <div class="subbidheading">Comments</div>

                      <v-text-field
                        label="Comments"
                        v-model="comments"
                        required
                        single-line
                        class="pt-0 currencyTitle"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <span>*Note - All time are in 24 hours format</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex style="text-align: center">
                      <v-btn
                        size="large"
                        color="white"
                        @click.native="
                          dialog = false;
                          closedd();
                        "
                        style="color: back"
                        :loading="x.loading"
                        >CANCEL</v-btn
                      >
                      <v-btn
                        size="large"
                        color="orange darken-1"
                        @click.native="submit"
                        v-on:bid-data="response"
                        style="color: white"
                        :loading="x.loading"
                        >SUBMIT</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-divider v-if="milestone"></v-divider>
              <v-layout v-if="milestone">
                <v-flex
                  xs12
                  md12
                  class="heading orange--text pt-2 pl-5"
                  style="text-align: initial"
                >
                  Milestone Information <br />
                </v-flex>
              </v-layout>
              <v-layout v-if="milestone">
                <v-flex xs12 md12 class="cont">
                  <ul class="timelineHori">
                    <li v-for="(item, i) in checkList" :key="i">
                      <span class="postion-set">{{ item.name }}</span>
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
              <v-snackbar
                :timeout="3000"
                bottom
                v-if="x.error"
                color="red darken-2"
                v-model="x.error"
                class="white--text"
                >{{ x.error }}</v-snackbar
              >
              <br />

              <success-dialog
                :content="x.message"
                :show="x.success"
                :onclose="closeMessage"
              />
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
  import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
  import SuccessDialog from "@/components/Common/SuccessDialog";
  import { sendBidpopupDriverList } from "@/constants/datatable-headers.js";
  import { sendBidpopupTransporterList } from "@/constants/datatable-headers.js";
  import ErrorBox from "@/components/Common/ErrorBox";
  import PaginationButtons from "@/components/Pagination/Pagination-New";
  import CustomerCurrency from "@/components/Common/CustomerCurrency";
  import moment from "moment";
  import {
    StorageKeys,
    TruckTypes,
    TruckArray,
  } from "../../constants/constants";
  import { procurement, milestoneList } from "../../constants/api-urls";

  export default {
    created() {
      this.TruckTypes = TruckTypes;
      this.payload = {
        skip: "0",
        limit: "15",
        status: "Transporter",
        jobId: this.address.jobId,
        tabOrder: 1,
      };

      this.$emit("transport-job", this.address);
    },
    components: {
      VueTimepicker,
      CustomerCurrency,
      SuccessDialog,
      PaginationButtons,
      ErrorBox,
      VueTimepicker,
    },

    watch: {
      isSelect(val) {
        if (!val || val == null) {
          this.selectedids = [];
        }
      },
      jobStartDatePicker(val) {
        const epoch = moment(val, "YYYY-MM-DD").unix();

        this.x.startDate = epoch;
      },
      jobPickupTimePicker(val) {
        this.x.pickupTime = val;
      },
    },

    data() {
      return {
        selected: [],
        checkList: [],
        TruckTypes: [],
        selectedids: [],
        selectednames: [],
        comments: "",
        wayToNotify: "",
        totalListdata: 0,
        openTimeDate: "",
        closeTimeDate: "",
        truckType: "",
        location: "",
        isSelect: "",
        search: "",
        dateToday: moment(new Date()).format("YYYY-MM-DD"),
        pickupTime: {
          HH: "",
          mm: "",
        },
        date: "2020-02-20",
        radios: "Transporter",
        AssignmentCount: this.address.assignCount,
        Rate: this.address.jobPrice,
        valid: true,
        lazy: false,
        DatePickerFormat: "dd/MM/yyyy",
        processing: false,
        componentKey: 0,
        startdate: moment.unix(this.address.startDate).format("YYYY-MM-DD"),
        startTime: this.address.pickupTime,
        pickUpTimeDate:
          moment.unix(this.address.startDate).format("YYYY-MM-DD") +
          " " +
          this.address.pickupTime,
        currency: this.address.customerCurrency,
        currency: this.address.customerCurrency,
        currentDate: null,
        jobId: 0,
        milestone: null,
        milestoneItems: [],
        start_date: "",
        Price: "",
        select: "",
        error: "",
        jobPickupTimePicker: "",
        jobStartDatePicker: "",
        date: null,
        jobStartDatePicker: null,
        closedDate: "",
        jobStartDateBool: false,
        headers: sendBidpopupDriverList,
        Transporterheaders: sendBidpopupTransporterList,

        rules: {
          required: (value) => !!value || "This field is required.",
          noWhiteSpace: (v) =>
            (v != null && v.trim().length > 0) || "Enter valid value.",
          emailRules: (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
            "E-mail must be valid",
          countryCode: (v) =>
            /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
            "Please Enter Valid Country Code.",
          phoneNumber: (v) =>
            /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
            "Please Enter Valid Mobile No.",
          number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
        },
        data: null,
        jobPickupTimeBool: false,
        jobPickupTimePicker: null,
        startDate: "",
        BidType: [
          { status: "Fixed", abbr: "Fixed" },
          { status: "Bid", abbr: "Bid" },
        ],
        x: {
          error: null,
          startDate: 0,
          message: "",
          loading: false,
          success: false,

          menu: false,

        error: null,
        missingField: null,
      },
      items: [],
      payload: {},
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
    };
  },
  computed: {
    AssignmentRate: function () {
      if (this.Price != 0 && this.radios == "Driver") {
        return Math.floor(
          this.Price / (this.address.assignCount - this.address.cancelled)
        );
      } else {
        return this.Price;
      }
    },
  },
  props: {
    address: Object,
  },

  methods: {
    getChecklist() {
      if (this.milestone) {
        this.milestoneItems.filter((x) => {
          if (this.milestone === x._id) {
            this.checkList = x.checkList;
          }
        });
        setTimeout(() => {
          var objDiv = document.getElementsByClassName("scrollDialog");
          for (var i = 0; i < objDiv.length; i++) {
            objDiv[i].scrollTo({
              top: objDiv[i].scrollHeight,
              left: 0,
              behavior: "smooth",
            });
          }
        }, 1000);
      }
    },
    getformFillDuration() {
      var diff = Math.abs(this.openTimeDate - this.closeTimeDate) / 1000; // hours
      var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      var minutes =
        Math.floor(diff / 60) % 60 < 10
          ? "0" + (Math.floor(diff / 60) % 60)
          : Math.floor(diff / 60) % 60;
      var seconds =
        diff % 60 < 10 ? "0" + Math.round(diff % 60) : Math.round(diff % 60);
      return minutes + ":" + seconds;
    },
    getText(arr = [], val) {
      let finalEl = null;
      finalEl = arr.find((el) => el.value == val);
      return finalEl ? finalEl.text : "NA";
    },
    getTransporterAndDriverData(type) {
      this.totalListdata = 0;

        let url = procurement.BidTransporterDriverList;
        delete this.axios.defaults.headers.common["token"];
        let body = {
          skip: "0",
          limit: "15",
          status: type,
          jobId: this.address.jobId,
          tabOrder: 1,
        };
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.loading = false;
            this.truckType = response.data.data.jobDetail.truckType;
            this.totalListdata = response.data.data.totalData;
            this.location = response.data.data.jobDetail.destinationCity;
          },
          (error) => {
            this.loading = false;
          }
        );
      },
      checkSelected(select, id, i, name) {
        this.x.error = "";
        if (select && !this.selectedids.includes(id)) {
          this.selectedids.push(id);
          this.selectednames.push(name + " " + "," + id);
        }
        if (!select && this.selectedids.includes(id)) {
          this.selectedids.splice(this.selectedids.indexOf(id), 1);
          this.selectednames.splice(
            this.selectednames.indexOf(name + " " + "," + id),
            1
          );
        }
      },
      searchAnything() {
        if (this.search) {
          this.payload = {
            skip: "0",
            limit: "15",
            status: this.radios,
            jobId: this.address.jobId,
            tabOrder: 1,
            searchText: this.search,
          };
        } else {
          this.payload = {
            skip: "0",
            limit: "15",
            status: "Transporter",
            jobId: this.address.jobId,
            tabOrder: 1,
          };
        }
      },
      reset() {
        this.search = "";
        this.selectedids = [];
        this.selectednames = [];
        if (this.radios == "Transporter") {
          this.payload = {
            skip: "0",
            limit: "15",
            status: "Transporter",
            jobId: this.address.jobId,
            tabOrder: 1,
          };
        } else {
          this.payload = {
            skip: "0",
            limit: "15",
            status: "Driver",
            jobId: this.address.jobId,
            tabOrder: 1,
          };
        }
      },
      paginationPayload(event) {
        this.loading = event;
        this.payload = event;
      },
      searchTypeTransporter(event) {
        this.getTransporterAndDriverData("Transporter");
        this.closedd();
        (this.isSelect = ""),
          (this.payload = {
            skip: "0",
            limit: "15",
            status: "Transporter",
            jobId: this.address.jobId,
            tabOrder: 1,
          });
      },
      searchTypeOwner() {
        this.getTransporterAndDriverData("Driver");
        this.closedd();
        (this.isSelect = ""),
          (this.payload = {
            skip: "0",
            limit: "15",
            status: "Driver",
            jobId: this.address.jobId,
            tabOrder: 1,
          });
      },
      response(event) {},
      callback(res) {
        this.loading = false;
        this.totalPages = Math.ceil(res.totalData / 15);
        this.items = res.list;
        this.items.forEach((e) => {
          e.selected = false;
          if (this.selectedids.includes(e.transporterId)) {
            e.selected = true;
          }
          if (this.selectedids.includes(e.driverId)) {
            e.selected = true;
          }
        });

      if (this.$refs.tableList) {
        this.$refs.tableList.scrollTo(0, 0);
      }
    },
    getMilestones() {
      let url = "/dmsAdmin/milestoneList";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        workingCountry: [this.address.workingCountry],
        assignDriver: "true",
      };

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.milestoneItems = response.data.data;
          },
          (error) => {
            this.error = "Failed to Fetch Milestone Status";
          }
        );
      },
      checkInternet() {
        if (!navigator.onLine) {
          this.loading = false;
          this.x.error = "Please check your internet connection";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return;
        } else {
          var currentDate = new Date().toISOString();
          currentDate = new Date(currentDate);
          let current = moment.utc(currentDate).format("YYYY-MM-DD HH:mm");
          if (this.pickUpTimeDate < current) {
            this.x.error = "Expiry date and time cannot be in the past";
            this.$emit("error", true);
            return false;
          } else {
            this.openTimeDate = new Date();
            this.getMilestones();
            this.dialog = true;
            if (this.address.workingCountry == "PAK") {
              this.wayToNotify = "SMS";
            } else {
              this.wayToNotify = "EMAIL";
            }
            this.getTransporterAndDriverData("Transporter");
            this.payload = {
              skip: "0",
              limit: "15",
              status: "Transporter",
              jobId: this.address.jobId,
              tabOrder: 1,
            };
          }
        }
      },
      checkTime() {
        let re = /^\d{1,2}:\d{2}([ap]m)?$/;
        var time = this.pickupTime.HH + ":" + this.pickupTime.mm;

        if (time != "" && !time.match(re)) {
          this.x.error =
            "Please select the valid pickup time before moving ahead!";
          time.focus();
          return false;
        }
      },

    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    closedd() {
      this.Price = "";
      this.isSelect = "";
      var objDiv = document.getElementsByClassName("scrollDialog");
      for (var i = 0; i < objDiv.length; i++) {
        objDiv[i].scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      this.select = "";
      (this.milestone = null),
        (this.selected = []),
        (this.selectedids = []),
        (this.jobModel = "");
      this.selectednames = [];
      this.selectedids.length = "";
      this.selected.length = "";
      this.selectednames.length = "";
      this.radios = "Transporter";
      this.jobPickupTimePicker = "";
      this.pickupTime = {
        HH: "",
        mm: "",
      };
      this.$refs.form.resetValidation();
      this.x.error = "";
      this.jobStartDatePicker = "";
    },

      allowedStep: (m) => m % 30 === 0,
      initLoadingAutocomplete() {
        // Create the autocomplete object, restricting the search predictions to
        // geographical location types.
        var autocomplete = new google.maps.places.Autocomplete(
          document.getElementById("autocomplete")
        );
      },

      getTime(time) {
        return moment.unix(time).format("DD/MM/YYYY");
      },
      getDateTime(time) {
        return moment.unix(time).format("DD/MM/YYYY hh:mm A");
      },
      haserror() {
        return this.x.error !== null;
      },
      rate() {
        if (!Rate) {
          Rate = NA;
        }
      },
      checkOnDate(startDate, closedDate) {
        startDate = new Date(startDate);

        closedDate = new Date(closedDate);

        var currentDate = new Date().toISOString();
        currentDate = new Date(currentDate);
        if (closedDate - startDate >= 0) {
          this.x.error =
            "Expiry date cannot be greater than start date and time";
          return false;
        } else if (closedDate - currentDate <= 0) {
          this.x.error = "Expiry date and time cannot be in the past";
          return false;
        } else return true;
      },
      submit() {
        if (!navigator.onLine) {
          this.loading = false;
          this.x.error = "Please check your internet connection";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return;
        }
        if (this.$refs.form.validate()) {
          this.snackbar = true;
          this.checkTime();
          if (this.processing) {
            return;
          } else {
            this.x.error = null;
            const { x, ...customerProfile } = this._data;
            if (!this.Price.trim()) {
              this.x.error = "Please provide amount before moving ahead";
              return false;
            }
            if (
              !this.selectedids.length &&
              this.radios == "Transporter" &&
              this.isSelect == "singleSelect"
            ) {
              this.x.error = "Please select at least one trasnporter";
              return false;
            }
            if (
              !this.selectedids.length &&
              this.radios == "Driver" &&
              this.isSelect == "singleSelect"
            ) {
              this.x.error = "Please select at least one driver";
              return false;
            }
            if (!this.jobStartDatePicker) {
              this.x.error = "Please provide expiry date before moving ahead";
              return false;
            }
            if (!this.pickupTime) {
              this.x.error = "Please provide expiry time before moving ahead";
              return false;
            }
            var time = this.pickupTime.HH + ":" + this.pickupTime.mm;
            var tempTime = new Date(this.x.startDate * 1000);
            var year = tempTime.getFullYear();
            var month = tempTime.getMonth() + 1;
            var day = tempTime.getDate();

            var start_date = new Date(
              year + "-" + month + "-" + day + " " + time
            );
            start_date = new Date(start_date).toISOString();

            var pickUpDate = new Date(this.address.startDate * 1000);
            var pickUpYear = pickUpDate.getFullYear();
            var pickUpMonth = pickUpDate.getMonth() + 1;
            var pickUpDay = pickUpDate.getDate();

            var pickUpDate = new Date(
              pickUpYear +
                "-" +
                pickUpMonth +
                "-" +
                pickUpDay +
                " " +
                this.address.pickupTime
            );
            pickUpDate = new Date(pickUpDate).toISOString();
            var date_flag = this.checkOnDate(pickUpDate, start_date);
            if (!date_flag) {
              return false;
            }

            this.loading = true;
            this.$emit("transport-job", this.address);
            let url = procurement.sendBidToTransportAndDriver;
            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            let config = {
              headers: {
                authorization: `bearer ${token}`,
              },
            };

          let body = {
            jobId: this.address.jobId,
            jobModel: "Bid",
            amount: this.Price,
            closedDate: start_date,
            type: this.radios,
            driverAmount: this.AssignmentRate,
            currency: this.currency,
            commentForBid: this.comments.trim(),
          };
          var x123 = this.select.status;
          this.address.jobModel = x123;
          if (this.isSelect == "singleSelect") {
            body.arrayOfIds = this.selectedids;
            body.isSelectedAll = false;
            body.notifyVia = this.wayToNotify;
          }
          if (this.milestone && this.milestone.length > 0) {
            body.milestoneId = this.milestone;
          }
          if (!this.isSelect) {
            body.isSelectedAll = true;
          }
          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              if (response.data.statusCode == 200) {
                this.closeTimeDate = new Date();
                this.identify(localStorage.getItem("email"));
                if (this.radios == "Transporter") {
                  this.track("Send For Bid", {
                    "Job Id": this.address.jobId,
                    "Company Name/Customer Name":
                      this.address.companyName ||
                      this.address.customer ||
                      this.address.customerEmail,
                    "No. of assignmnets":
                      this.address.numberOfAssignmentsRequired,
                    "Job Start Date": moment(this.pickUpTimeDate).format(),
                    "Expiry Date": start_date,
                    "Working Country": this.address.workingCountry,
                    "Bidding Price": this.Price,
                    "Accepted Price": this.address.jobPrice,
                    "Bid For": this.radios,
                    "Transporter names": this.selectednames.length
                      ? this.selectednames
                      : "NA",
                    "Manual Bid":
                      this.isSelect == "singleSelect" ? "true" : "false",
                    "Bid Type": "Bid",
                    "Send Bid Duration": this.getformFillDuration(),
                  });
                }
                if (this.radios == "Driver") {
                  this.track("Send For Bid", {
                    "Job Id": this.address.jobId.toString(),
                    "Compnay Name":
                      this.address.companyName ||
                      this.address.customer ||
                      this.address.customerEmail,
                    "No. of assignmnets":
                      this.address.numberOfAssignmentsRequired,
                    "Job Start Date": moment(this.pickUpTimeDate).format(),
                    "Expiry Date": start_date,
                    "Working Country": this.address.workingCountry,
                    "Bidding Price": this.Price,
                    "Accepted Price": this.address.jobPrice,
                    "Bid For": this.radios,
                    "Driver names": this.selectednames.length
                      ? this.selectednames
                      : "NA",
                    "Manual Bid":
                      this.isSelect == "singleSelect" ? "true" : "false",
                    "Per Assignment Price": this.AssignmentRate,
                    "Send Bid Duration": this.getformFillDuration(),
                  });
                }

                  this.closedd();
                  this.dialog = false;
                  this.loading = false;
                  this.$emit("bid-success", this.address);
                }
              },
              (error) => {
                this.loading = false;
                this.processing = false;
                this.x.error = error.response.data.message;
                this.x.success = false;
              }
            );
          }
        }
      },
    },
  };
</script>
<style lang="scss">
  .v-dialog.v-dialog--active.v-dialog--persistent {
    max-height: 93%;
  }
  .vue__time-picker input.display-time {
    border-radius: 0px !important;
    border-bottom: 1px solid #d2d2d2 !important;
    border: none;
    padding: 0px !important;
  }
  .customRadio .v-input--selection-controls__input {
    position: absolute;
    right: 0px;
    top: 8px;
  }
  .icon1.customLabel::before {
    display: block;
    content: url(../../assets/Driver.svg);
    background-size: 28px 28px;
    height: 28px;
    width: 28px;
    margin: auto;
  }
  .icon2.customLabel::before {
    display: block;
    content: url(../../assets/truck-unselected.svg);
    background-size: 28px 28px;
    height: 28px;
    margin: auto;
  }
  .icon1.customLabel.orange--text::before {
    content: url(../../assets/driver-selected.svg);
  }
  .icon2.customLabel.orange--text::before {
    content: url(../../assets/Truck.svg);
  }
  .customLabel label.v-label.theme--light {
    bottom: -26px !important;
    position: absolute !important;
    top: auto;
  }
  .customRadio .v-radio.theme--light {
    border: 3px solid;
    height: 100px;
    padding: 0px 20px;
    position: relative;
    border-radius: 10px;
    width: 142px;
    color: lightgray;
  }
  .customLabel.orange--text label.v-label.theme--light {
    color: orange;
  }
  .bidheading {
    font-size: 19px;
    color: grey;
  }
  .spacerBid {
    max-height: 200px !important;
  }

  .spacerBid:-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  .spacerBid::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  .spacerBid::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  .spacerBid::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }

  .spacerBid::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
  }
</style>
<style scoped>
  .min_width_89 {
    min-width: 89px !important;
  }
  .minHeight {
    min-height: 300px;
  }
  .postion-set {
    word-break: break-all;
  }
  .cont {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .timelineHori {
    counter-reset: step;
    display: flex;
    overflow: auto;
    overflow-y: hidden;
  }
  .timelineHori li:before {
    content: "1";
    width: 30px;
    height: 30px;
  }
  .timelineHori li:before {
    content: "1";
    width: 30px;
    height: 30px;
    border: 2px solid white;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    color: white;
    text-align: center;
    font-weight: bold;
  }
  .timelineHori li:first-child:after {
    content: none;
  }

  .timelineHori li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 6px;
    background: orange;
    top: 15px;
    left: -50%;
    z-index: -1;
  }
  .timelineHori li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: orange;
    color: white;
    text-align: center;
    font-weight: bold;
  }
  .timelineHori li {
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    list-style-type: none;
  }
  .extraTextStyle {
    color: orange;
    position: relative !important;
    color: orange;
    font-size: 16px;
  }
  .subbidheading {
    font-size: 14px !important;
    font-weight: 400;
    color: gray;
  }
  .spacerclass {
    overflow-y: scroll !important;
    max-height: calc(110vh - 176px) !important;
  }
  .break_long_text {
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }
  .user {
    padding: 10px;
  }

  .bid-pop {
    padding: 10px;
  }
  .comments-view .v-toolbar {
    width: 100%;
  }
  .v-toolbar--fixed {
    position: inherit;
  }
  .m {
    margin-bottom: 10px;
  }
  .assign {
    padding-left: 250px;
    font-size: 16px;
  }
  .ml {
    margin-left: 30px;
  }
  .pt {
    padding-top: 3px;
  }

  div ::-webkit-scrollbar-thumb {
    background: darkorange;
  }
  div ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    color: rgba(0, 0, 0, 0.54);
  }
  .active {
    background-color: darkorange !important;
  }

  .vue__time-picker input.display-time {
    border-radius: 0px !important;
    border-bottom: 1px solid #d2d2d2 !important;
    border: none;
    padding: 0px !important;
  }
  .pt-20 {
    padding: 18px;
  }
  .fonttime {
    font-size: 15px;
  }
  .test9 {
    position: absolute;
    fill: rgba(0, 0, 0, 0.54);
    left: -1px;
    top: 20px;
    margin: 0 !important;
  }
</style>
