<template>
  <div class="log">
    <button
      v-permissions="'view-details-jobs'"
      @click.stop="dialog = false"
      @click="getAssignments"
      title="View Assignments"
      target="_blank"
      class="view-ass-btn"
    >
      View
    </button>

    <v-dialog v-model="dialog" max-width="80%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>View Assignments</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="spacerClass">
            <v-card-text class="nospace">
              <v-stepper v-model="e1" class="no-transition">
                <v-stepper-header class="SubTypeTab">
                  <template v-for="n in steps" class="bg-clr-tab">
                    <v-stepper-step
                      :complete="e1 == n"
                      :key="`${n}-step`"
                      :step="n"
                      :editable="true"
                      color="orange darken-1"
                      complete-icon
                      class="bg-clr-tab"
                    >
                      <span>{{ getJobType(jobTypeArray[n - 1]) }} </span>
                    </v-stepper-step>
                  </template>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content
                    v-for="n in steps"
                    :key="`${n}-content`"
                    :step="n"
                  >
                    <v-flex>
                      <v-data-table
                        id="jobsTable"
                        hide-actions
                        :headers="Section == 'Sales' ? headersSales : headers"
                        v-if="tabArray.length"
                        :items="tabArray[n - 1].assignmentListing"
                      >
                        <template slot="items" slot-scope="props">
                          <td>{{ props.item.assignmentId }}</td>
                          <td>
                            {{ getJobType(props.item.jobType) || "Land" }}
                          </td>

                          <td>
                            {{ getText(truckTypes, props.item.truckType) }}
                          </td>
                          <td>
                            {{ assignmentStatus(props.item.assignmentStatus) }}
                          </td>
                          <td v-if="props.item.driverData && !Section">
                            {{ props.item.driverData.name }}({{
                              props.item.driverData.driverId
                            }})
                            <br />
                            {{
                              props.item.driverData.countryCode +
                              "-" +
                              props.item.driverData.phoneNo
                            }}
                          </td>
                          <td v-else-if="!Section">-</td>
                          <td v-if="props.item.driverData && !Section">
                            <v-flex>
                              <div v-if="props.item.truckPrefix">
                                {{ props.item.truckPrefix }}-{{
                                  props.item.truckNumber
                                }}
                              </div>
                              <div v-else>
                                {{ props.item.driverData.truckPrefix }}-{{
                                  props.item.driverData.truckNumber
                                }}
                              </div>
                            </v-flex>
                          </td>
                          <td v-else-if="!Section">-</td>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import {
  jobListAssignmentpopup,
  jobListAssignmentpopupSales,
} from "@/constants/datatable-headers.js";
import { StorageKeys, TruckTypes } from "../../constants/constants";
import DriverQuote from "./DriverQuote.vue";
import { procurement } from "../../constants/api-urls";
export default {
  data() {
    return {
      truckTypes: TruckTypes,
      tabArray: [],
      e1: 1,
      steps: 1,
      jobType: null,
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      headers: jobListAssignmentpopup,
      headersSales: jobListAssignmentpopupSales,
    };
  },
  props: {
    jobId: Number,
    Section: String,
  },
  created() {
    this.jobTypeArray = [];
  },
  components: {
    ErrorBox,
    DriverQuote,
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    getText(arr = [], val) {
      let finalEl = null;

      finalEl = arr.find((el) => el.value === val.toString());

      return finalEl ? finalEl.text : "NA";
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },

    getTime(date) {
      return moment(date).format("YYYY-MM-DD hh:mm A");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";
        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    getAssignments() {
      console.log("this.jobId", this.jobId);
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      this.dialog = true;
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let url = procurement.jobDataForPopup;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          jobId: this.jobId.toString(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.dialog = true;
            this.jobTypeArray = [];
            console.log(response);
            var size = response.data.data.assignmentList.length;
            this.steps = size;
            for (var i = 0; i < size; i++) {
              this.jobTypeArray[i] =
                response.data.data.assignmentList[i].jobType || "1";
            }
            this.tabArray = response.data.data.assignmentList;

            this.processing = false;
          },
          (error) => {
            this.x.error = "Failed to Fetch Assignments";
            this.processing = false;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.v-stepper__header {
  justify-content: left !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.spacerClass {
  max-height: 400px;
}
.tabsPadding {
  padding: 9px !important;
}
.no-transition .stepper__content {
  transition: none;
}
</style>
